<template>
  <div id="nfeventsapp">
    <ReiseSuche></ReiseSuche>
    <ResultList></ResultList>
  </div>
</template>

<script>
import ReiseSuche from "./ReiseSuche";

import ResultList from "./ResultList";

export default {
  name: "AppWrapper",
  components: {
    ReiseSuche,
    ResultList
  }
};
</script>

<style lang="scss">
#nfeventsapp {
  color: black;
  margin-bottom: 2em;
}
</style>
