const R = require("ramda");


export const setItems = (state, payload) => {
    state.items = payload;
};

export const setLoading = (state, payload) => {
    state.isLoading = payload;
};
