<template>
  <div class="resultmap">
    <l-map
      ref="myMap"
      :zoom="zoom"
      :center="center"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      ></l-tile-layer>
      <v-marker-cluster
        :options="clusterOptions"
        ref="clusterRef"
      >
        <l-marker
          v-for="i in items"
          :lat-lng="[i.lat,i.lon]"
          :key="i.id"
          :title="i.title"
          :icon="icon"
        >
          <l-popup>
            <div class="item-popup">

              <div class='date'><i class="fa fa-calendar"></i> {{i.date_str}}</div>

              <a
                :href="i.link"
                class="title"
              >
                <div>
                  {{i.title}}
                </div><img :src="i.image_link">
              </a>
              <div class="txt">
                <strong>{{i.organisation}}:</strong>

                {{i.text}}
              </div>

            </div>

          </l-popup>
        </l-marker>
      </v-marker-cluster>
    </l-map>
  </div>

</template>

<script>
const R = require("ramda");
import { LMap, LTileLayer, LMarker, LPopup, LIcon } from "vue2-leaflet";
import Vue2LeafletMarkerCluster from "vue2-leaflet-markercluster";

export default {
  name: "ResultMap",
  data: function() {
    return {
      zoom: 5,
      center: L.latLng(41.41322, -1.219482),
      url:
        "https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}{r}.png",
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
      // url:
      //   "https://api.mapbox.com/styles/v1/mapbox/outdoors-v9/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoibmF0dXJmcmV1bmRlIiwiYSI6IlVqNkMwTUkifQ.dFf2zH0dWyxAbK0BI5Pdxw",

      maxZoom: 16,

      marker: L.latLng(47.41322, -1.219482),
      clusterOptions: {
        spiderfyDistanceMultiplier: 2,
        maxClusterRadius: 30
      },
      icon: L.icon({
        iconUrl:
          "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41]
        //   iconSize: [32, 37],
        //   iconAnchor: [16, 37]
      })
    };
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    "v-marker-cluster": Vue2LeafletMarkerCluster
  },
  watch: {
    items: function(new_v, old_v) {
      // var latLngBounds = a.layer.getBounds();
      console.log("#log", "repaint");

      this.$nextTick(() => {
        let bounds = this.$refs.clusterRef.mapObject.getBounds();

        this.$refs.myMap.mapObject.fitBounds(bounds, { maxZoom: 5 });
      });

      // this.paintMarkers(new_v);
    }
  },
  methods: {
    hasLocation: function(item) {
      if (item.lat !== null && item.lon != null) {
        return true;
      } else {
        return false;
      }
    }
  },
  computed: {
    items: function() {
      return R.filter(this.hasLocation, this.$store.getters.items);
    }
  }
};
</script>

<style lang="scss">
@import "../styles/settings.scss";
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

.resultmap {
  color: black;
  width: 100%;
  height: 600px;
  .leaflet-pane {
    z-index: 80;
  }
  .leaflet-popup-content-wrapper {
    border-radius: 0;
  }
  .item-popup {
    min-width: 200px;
    padding: 1em;
    .title {
      display: block;
      font-size: 20px;
      color: $c-red;
      margin-bottom: 1em;
    }
    .text {
    }
  }
}
</style>

