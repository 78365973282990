import axios from "axios";

const apiurl = "https://reisen.naturfreunde.at/events/ng_items";

export const fetchItems = ({
  commit,
  /*state,*/
  getters
}, query) => {
  console.log("fetchItems", query);
  commit("setLoading", true);
  return new Promise((resolve, reject) => {
    axios
      .post(apiurl, query)
      .then(function (response) {
        console.log("items response", response);
        commit("setLoading", false);
        commit("setItems", response.data.items);
        resolve();
      })
      .catch(function (error) {
        commit("setLoading", false);
        reject(error.response);
      });
  });
};
