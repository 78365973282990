<template>
  <div class="reisesuche">

    <div class="line-1">
      <div class="col-input">
        <input
          placeholder="Stichwort"
          class="form-control"
          v-model="data.keyword"
          name="keyword"
          v-on:keyup.enter="searchFormSubmit('main_submit')"
        >

      </div>
      <div class="col-btn">
        <button
          class="btn  btn-default btn-blank"
          type="button"
          @click.prevent="searchFormSubmit('main_submit')"
        ><span class="txt">Angebote suchen </span><i class="fa fa-search"></i></button>
      </div>
      <div class="col spinner">
        <i
          v-show="isLoading"
          class='fa fa-spinner fa-rotate fa-spin'
        ></i>
      </div>
    </div>
    <div class="line-2">
      <div class="col-1">
        <div class="form-group">
          <label for="country">Destinationen</label>
          <select
            class="form-control"
            v-model="data.country"
            name="country"
          >
            <option
              v-for="option in countries"
              v-bind:value="option.key"
              :key="option.key"
            >
              {{ option.val }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-2">
        <div class="form-group">
          <label for="query_maincategoryid">Reiseart</label>
        </div>
        <ul class="subcat-items fa-ul">
          <li
            v-for="c in reiseart"
            :key="c.key"
          ><a @click.prevent="toggleKey(data.reisearten,c.key)"><i
                class="fa fa-li fa-lg"
                :class="[ hasKey(data.reisearten,c.key) ? 'fa-check-circle':'fa-circle-o' ]"
              ></i>{{c.val}}</a></li>
        </ul>
      </div>
      <div class="col-3">
        <div class="form-group">
          <div class="datepickers">
            <label for="query_date1">Zeitraum von</label>
            <div class="input-group">
              <Datepicker
                input-class="form-control"
                :typeable="true"
                :language="de"
                :monday-first="true"
                :minimumView="'day'"
                :maximumView="'day'"
                v-model="data.date1"
                :clear-button="true"
                clear-button-icon="fa fa-times"
                ref="picker1"
              > </Datepicker>

              <span class="input-group-btn">
                <button
                  type="button"
                  class="btn btn-default"
                  @click.prevent="open('picker1')"
                ><i class="fa fa-calendar"></i></button>
              </span>
            </div>
            <div>&nbsp;</div>
            <label for="query_date2">Zeitraum bis</label>

            <div class="input-group">
              <Datepicker
                input-class="form-control"
                :typeable="true"
                :language="de"
                :monday-first="true"
                :minimumView="'day'"
                :maximumView="'day'"
                :clear-button="true"
                clear-button-icon="fa fa-times"
                v-model="data.date2"
                ref="picker2"
              > </Datepicker>

              <span class="input-group-btn">
                <button
                  type="button"
                  class="btn btn-default"
                  @click.prevent="open('picker2')"
                ><i class="fa fa-calendar"></i></button>
              </span>
            </div>

          </div>

        </div>

      </div>

    </div>
  </div>

</template>

<script>
// const {create, env} = require('sanctuary');
// const checkTypes = process.env.NODE_ENV !== 'production';
// const S = create({checkTypes, env});
const R = require("ramda");
import Datepicker from "vuejs-datepicker";
import { de } from "vuejs-datepicker/dist/locale";

import debounce from "lodash.debounce";

import Vue from "vue";
// some JS file
import store from "../store";

export default {
  name: "ReiseSuche",
  props: {},
  data: function() {
    return {
      de: de,
      data: {
        keyword: "",
        country: null,
        reisearten: [],
        date1: null,
        date2: null
      }
    };
  },
  methods: {
    searchFormSubmit: function(mode) {
      console.log("#submit", this.data);

      let query = this.data;
      query.date1 = this.fixDate(query.date1);
      query.date2 = this.fixDate(query.date2);
      if (query.reisearten) {
        query.subcategoryids = query.reisearten.join(",");
      }
      query = R.omit(["reisearten"], query);
      this.$store.dispatch("fetchItems", { ...query, pagesize: 1000 });
    },
    fixDate: function(dateObj) {
      if (dateObj) {
        var month = dateObj.getUTCMonth() + 1; //months from 1-12
        var day = dateObj.getUTCDate();
        var year = dateObj.getUTCFullYear();

        return year + "-" + month + "-" + day;
      }
      return null;
    },
    open: function(refstr) {
      this.$refs[refstr].showCalendar();
      this.$refs[refstr].$el.querySelector("input").focus();
    },
    toggleKey: function(bucket, item) {
      if (this.hasKey(bucket, item)) {
        this.$delete(bucket, bucket.indexOf(item));
      } else {
        bucket.push(item);
      }
    },
    hasKey: function(bucket, item) {
      return R.contains(item, bucket);
    },
    fetchItemsIfNeeded: function() {
      this.$store.dispatch("fetchItems", { pagesize: 1000 });
    }
  },
  components: {
    Datepicker
  },
  created: function() {
    this.fetchItemsIfNeeded();
  },
  watch: {
    data: {
      handler: debounce(function(v) {
        this.searchFormSubmit();
      }, 1000),
      deep: true
    }
  },
  computed: {
    reiseart: function() {
      return this.$store.getters.travelCategories;
    },
    countries: function() {
      return [{ key: null, name: "" }, ...this.$store.getters.countries];
    },
    isLoading: function() {
      return this.$store.getters.isLoading;
    }
  }
};
</script>

<style lang="scss">
@import "../styles/settings.scss";

.reisesuche {
  margin: 0;
  .line-1 {
    background: $c-red;
    @include flexbox;
    > * {
      padding: 1em;
      @include flex(1, 1, auto);
    }
    > .col-input {
      @include flex(1, 1, 80%);

      max-width: 400px;
    }
    > .col.spinner {
      text-align: right;
      color: white;
      font-size: 20px;
    }
  }
  .line-2 {
    background: #eeeeee;
    @include flexbox;
    > * {
      padding: 1em;
      @include flex(1, 1, auto);
    }
  }
  .subcat-items {
    li {
      margin: 5px 0;

      a {
        color: black;

        .fa {
          color: $c-red;
        }
      }
    }
  }
  .datepickers {
    input.form-control {
      background-color: white;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    max-width: 200px;
    .vdp-datepicker__clear-button {
      position: absolute;
      right: 10px;
      z-index: 99;
      top: 10px;
    }
  }
}
</style>

