/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// require('./bootstrap');

// import 'babel-polyfill';
// import 'es6-promise/auto';

import Vue from "vue";
window.Vue = Vue;

import store from "./store";
// import router from './router';
import AppWrapper from "./components/AppWrapper";

import VueVisible from "vue-visible";
Vue.use(VueVisible);

Vue.config.productionTip = false;

// import { sync } from 'vuex-router-sync'
// sync(store, router);

/* eslint-disable no-new */

import {
  Icon
} from 'leaflet'
import 'leaflet/dist/leaflet.css'
// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

window.nfeventsApp = new Vue({
  el: "#nfeventsapp",
  // router,
  store,
  template: "<AppWrapper/>",
  components: {
    AppWrapper
  }
});
