<template>

  <div v-show="!hideResults">

    <div
      class="alert alert-danger"
      v-for="(key, value) in debug_data"
      :key="key"
    ><b>{{key}}</b>:{{value}}</div>

    <div class="angebotssuche-listing">

      <div>

        <div class='c4p-tabbed-block'>
          <div class="defaultcontainer">

            <div
              id="tabbed-block-$ID"
              class="r-tabs"
            >

              <!-- <div class="sortby-wrap pull-right">Sortieren nach
                <select
                  class="form-control input-sortby input-sm"
                  ng-options="key as name for (key , name) in sortbys"
                  ng-model="app.query.sortby"
                ></select>

              </div> -->
              <ul class='tab-pager r-tabs-nav'>
                <li
                  class="r-tabs-tab"
                  v-bind:class="(currentTab=='map')?'r-tabs-state-active':'r-tabs-state-default'"
                ><a
                    href="javascript:void(0)"
                    class="r-tabs-anchor"
                    @click="setTab('map')"
                  >Ergebnisse auf Karte</a></li>
                <li
                  class="r-tabs-tab"
                  :class="currentTab=='list'?'r-tabs-state-active':'r-tabs-state-default'"
                ><a
                    href="javascript:void(0)"
                    class="r-tabs-anchor"
                    @click="setTab('list')"
                  >Ergebnisse als Liste</a></li>

                <li class='r-tabs-tab itemcount'>
                  <div>
                    <span class="loadingicon"><img
                        src="/Mwerkzeug/images/loading.gif"
                        width="16"
                        height="11"
                        alt="Loading"
                        v-show="isLoading"
                      ></span>

                    <span v-if="total_items==1">1 Angebot gefunden</span>
                    <span v-else>{{total_items}} Angebote gefunden</span>
                  </div>

                </li>

              </ul>

              <div
                class="tab-pane r-tabs-panel"
                v-bind:class="(currentTab=='list')?'r-tabs-state-active':'r-tabs-state-default'"
                id="tab-list"
                v-show="currentTab=='list'"
              >

                <div ng-if="currentTab=='list'">

                  <div
                    v-show="total_items==0"
                    class="no-items-found"
                  >
                    <div>&nbsp;</div>
                    <div v-show="!ortsgruppenName"><i class="fa fa-warning fa-2x fa-fw"></i> keine Veranstaltungen
                      gefunden</div>

                    <div v-show="ortsgruppenName">Derzeit sind keine Veranstaltungen der OG
                      {{ortsgruppenName}} online.<div>&nbsp;</div>
                      Bitte wechsle über die oben stehende Angebotssuche auf die Veranstaltungen in deinem Bundesland.</div>

                  </div>

                  <div class="c4p-teaser-list layout-tabbed">
                    <div class="teaser-container">

                      <div
                        class='c4p-teaser'
                        ng-repeat="i in items"
                        v-for="i in items"
                        :key="i.id"
                        :data-c4plink='i.c4plink'
                      >
                        <a
                          class="teaserlink plain-teaser"
                          :href="i.link"
                        >
                          <div
                            class="thumbnail"
                            v-show="i.image_link"
                            :style="{'background-image':'url(\''+i.image_link+'\')'}"
                          ></div>
                          <div class="text">
                            <div class='date'><i class="fa fa-calendar"></i> {{i.date_str}}
                            </div>
                            <h4>{{i.title}}</h4>
                            <div class='text'>
                              <!-- <strong>{{i.organisation}}:</strong> -->
                              {{i.text}}
                            </div>
                          </div>
                        </a>
                        <a
                          :href="'/BE/Angebote/edit/'+i.ev_id"
                          class="btn btn-sm"
                          target="_blank"
                          v-show="isadmin"
                        ><i class="fa fa-pencil"></i> edit</a>
                      </div>

                    </div>
                  </div>

                </div>
              </div>
              <div
                class="tab-pane r-tabs-panel"
                v-bind:class="(currentTab=='map')?'r-tabs-state-active':'r-tabs-state-default'"
                id="tab-map"
                v-show="currentTab=='map'"
              >
                <div class="c4p-teaser-map layout-events">
                  <div>&nbsp;</div>

                  <ResultMap v-if="currentTab=='map'"></ResultMap>

                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

    </div>

  </div>

</template>

<script>
const R = require("ramda");
import ResultMap from "./ResultMap";

export default {
  name: "ResultList",
  data: function() {
    return {
      currentTab: "map",
      listStatus: "loaded",
      isadmin: false,
      hideResults: false,
      debug_data: {},
      ortsgruppenName: null,
      items_pagination: {
        total_items: 30,
        page: 1,
        pagesize: 55
      }
    };
  },
  methods: {
    setTab: function(newtab) {
      this.currentTab = newtab;
    }
  },
  computed: {
    items: function() {
      return this.$store.getters.items;
    },
    isLoading: function() {
      return this.$store.getters.isLoading;
    },
    total_items: function() {
      return this.items.length;
    }
  },
  components: {
    ResultMap
  }
};
</script>

<style lang="scss">
@import "../styles/settings.scss";

.angebotssuche-listing {
  .c4p-teaser-map {
    padding-top: 1em;
    min-height: 600px;
  }
  @media (max-width: 639px) and (min-width: 1px) {
    div.r-tabs .r-tabs-nav .r-tabs-tab.itemcount {
      background-color: #aaa;
    }

    div.r-tabs .r-tabs-nav > li:first-child {
      display: inline-block !important;
    }
  }
}
</style>

